import React from "react"

import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"

import { Link as GatsbyLink } from "gatsby"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"
import { countryData, paymentMethodData } from "../data/order"
import { Country, PaymentMethod } from "../api/sync"
import CentsToEuro from "../utils/cents-to-euro"

const ShippingAndPaymentPage = () => {
  return (
    <MainShell breadcrumbs={[{ label: `Verzenden en betalen` }]}>
      <Helmet>
        <title>Verzenden en betalen - Todocards.nl</title>

        <meta
          name="description"
          content="Wij werken samen met verschillende partijen om de betaling en verzending van jouw bestelling zo snel, veilig en goedkoop mogelijk te laten verlopen."
        />
      </Helmet>

      <Typography variant="h1" gutterBottom>
        Verzenden en betalen
      </Typography>

      <Typography paragraph={true}>
        Wanneer we jouw bestelling hebben verstuurd ontvang je een e-mail met
        daarin een link waarmee je het pakket kunt volgen. Wij werken samen met
        verschillende vervoerders, maar meestal zullen we jouw pakket via PostNL
        versturen. Heb je zelf een voorkeur voor een bepaalde vervoerder, neem
        dan{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contact
        </Link>{" "}
        met ons op.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Levertijden en verzendkosten
      </Typography>

      <Typography paragraph={true}>
        Afhankelijk van het land waar de bestelling naartoe gestuurd moet worden
        gelden de volgende levertijden en verzendkosten:
      </Typography>

      <Box mb={2}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Land</TableCell>
                <TableCell>Verzendkosten</TableCell>
                <TableCell>Gratis verzending vanaf</TableCell>
                <TableCell>Levertijd</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(Object.values(Country) as Country[]).map(country => (
                <TableRow key={country}>
                  <TableCell>{countryData[country].label}</TableCell>
                  <TableCell>
                    {CentsToEuro(countryData[country].shippingCosts)}
                  </TableCell>
                  <TableCell>
                    {CentsToEuro(countryData[country].shippingCostsThreshold)}
                  </TableCell>
                  <TableCell>{countryData[country].shippingTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Typography variant="h2" gutterBottom>
        Betalen
      </Typography>

      <Typography paragraph={true}>
        Wij werken samen met{" "}
        <Link
          href="https://www.mollie.com"
          target="_blank"
          rel="noopener noreferrer nofollow"
          underline="always"
        >
          Mollie
        </Link>{" "}
        om jouw betaling efficiënt en veilig te kunnen verwerken. Tijdens het
        bestelproces word je omgeleid naar een pagina van Mollie, hier kan je de
        betaling doen. Na het afronden van de betaling word je teruggestuurd
        naar Todocards.nl en krijgen wij van Mollie een seintje dat de betaling
        gelukt is.
      </Typography>

      <Typography paragraph={true}>
        Op deze manier kunnen wij de volgende betaalmethodes aanbieden:
      </Typography>

      <Grid container spacing={2}>
        {(Object.values(PaymentMethod) as PaymentMethod[]).map(
          paymentMethod => (
            <Grid key={paymentMethod} item xs={6} sm={3} md={2}>
              <Box mb={2}>
                <img
                  src={paymentMethodData[paymentMethod].img}
                  width="100%"
                  alt={paymentMethodData[paymentMethod].label}
                />
                <Typography component="div" className="bold" align="center">
                  {paymentMethodData[paymentMethod].label}
                </Typography>
              </Box>
            </Grid>
          )
        )}
      </Grid>

      <Typography>
        Betaal je liever op een andere manier? Neem dan{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contact
        </Link>{" "}
        met ons op.
      </Typography>
    </MainShell>
  )
}

export default ShippingAndPaymentPage
